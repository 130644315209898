import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/Users/jxie/projects/lyra/packages/web/src/components/content/ContentPage.tsx";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Grid = makeShortcode("Grid");
const Img = makeShortcode("Img");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "help-your-teenager-by-managing-your-own-anxiety"
    }}>{`Help your teenager by managing your own anxiety`}</h1>
    <h3 {...{
      "id": "dont-feed-your-teenagers-anxiety"
    }}>{`Don’t feed your teenager’s anxiety.`}</h3>
    <p>{`The way `}<em parentName="p"><strong parentName="em">{`you`}</strong></em>{` respond to your teenager’s anxiety can affect the way your teenager handles situations that make them anxious. You should try to separate your own anxieties and worries from your teenager’s. Try to remain calm and relaxed when you are talking with your teenager about their anxiety.`}</p>
    <h3 {...{
      "id": "lead-by-example"
    }}>{`Lead by example.`}</h3>
    <p>{`Whether they are aware of it or not, teenagers can learn to be anxious by observing their parent’s anxious behaviour. So when you think about how your teenager handles anxiety, also think about how you handle anxiety yourself. For example, if a teenager sees that their parent is afraid of spiders and freaks out whenever they see one, the teenager may think that all spiders are dangerous and develop this fear themselves.`}</p>
    <p>{`If you have a tendency to be anxious, you should try to set a good example by showing your teenager how you actively use strategies to deal with your own anxieties. You can even share appropriate examples of facing your fears with your teenager!`}</p>
    <Grid container mdxType="Grid">
  <Grid item xs={12} md={10} sm={12} lg={6} mdxType="Grid">
    <Img src='/images/m9/44-01-AUS-version.svg' alt='Showing reaction to anxious situation' mdxType="Img" />
  </Grid>
  <Grid item xs={12} md={10} sm={12} lg={6} mdxType="Grid">
    <Img src='/images/m9/44-02.svg' alt='Showing reaction to anxious situation' mdxType="Img" />
  </Grid>
    </Grid>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      